<template>
  <svg
    :class="['trendline', colorClass]"
    :color="color"
    :stroke-width="lineWidth"
    :viewBox="`0 0 ${parsedWidth} ${parsedHeight}`"
  >
    <defs v-if="fill">
      <linearGradient :id="uid" x1="0" y1="100%" x2="0" y2="0">
        <stop offset="0" stop-color="currentColor" stop-opacity="0.15" />
        <stop offset="1" stop-color="currentColor" stop-opacity="0.15" />
      </linearGradient>
    </defs>

    <path
      :d="genPath(fill)"
      :fill="fill ? `url(#${uid})` : 'none'"
      :stroke="fill ? 'none' : 'currentColor'"
    />

    <path
      v-if="fill"
      :d="genPath(false)"
      fill="none"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
import { uniqueId } from '@/lib/utils'

function int (value) {
  return parseInt(value, 10)
}

export function checkCollinear (p0, p1, p2) {
  return int(p0.x + p2.x) === int(2 * p1.x) && int(p0.y + p2.y) === int(2 * p1.y)
}

export function getDistance (p1, p2) {
  return Math.sqrt(
    Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2),
  )
}

export function moveTo (to, from, radius) {
  const vector = { x: to.x - from.x, y: to.y - from.y }
  const length = Math.sqrt((vector.x * vector.x) + (vector.y * vector.y))
  const unitVector = { x: vector.x / length, y: vector.y / length }

  return {
    x: from.x + unitVector.x * radius,
    y: from.y + unitVector.y * radius,
  }
}

export function genPath (_points, radius, fill, height) {
  const points = [..._points]

  if (points.length === 0) return ''

  const start = points.shift()
  const end = points[points.length - 1]

  return (
    (fill ? `M${start.x} ${height - start.x + 2} L${start.x} ${start.y}` : `M${start.x} ${start.y}`) +
    points
      .map((point, index) => {
        const next = points[index + 1]
        const prev = points[index - 1] || start
        const isCollinear = next && checkCollinear(next, point, prev)

        if (!next || isCollinear) {
          return `L${point.x} ${point.y}`
        }

        const threshold = Math.min(
          getDistance(prev, point),
          getDistance(next, point),
        )
        const isTooCloseForRadius = threshold / 2 < radius
        const radiusForPoint = isTooCloseForRadius ? threshold / 2 : radius

        const before = moveTo(prev, point, radiusForPoint)
        const after = moveTo(next, point, radiusForPoint)

        return `L${before.x} ${before.y}S${point.x} ${point.y} ${after.x} ${after.y}`
      })
      .join('') +
    (fill ? `L${end.x} ${height - start.x + 2} Z` : '')
  )
}

function genPoints (values, boundary) {
  const { minX, maxX, minY, maxY } = boundary
  const totalValues = values.length

  const maxValue = Math.max(...values)
  const minValue = Math.min(...values)

  if (!values.length || minValue === maxValue) {
    return [
      { x: minX, y: minY, value: 0 },
      { x: maxX, y: minY, value: 0 },
    ]
  }

  const gridX = (maxX - minX) / (totalValues - 1)
  const gridY = (maxY - minY) / ((maxValue - minValue) || 1)

  return values.map((value, index) => {
    return {
      x: minX + index * gridX,
      y: maxY - (value - minValue) * gridY,
      value,
    }
  })
}

export default {
  name: 'Trendline',
  props: {
    color: {
      type: String,
      default: null,
    },
    colorClass: {
      type: String,
      default: null,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    smooth: {
      type: [Boolean, Number, String],
      default: true,
    },
    lineWidth: {
      type: [String, Number],
      default: 2,
    },
    padding: {
      type: [String, Number],
      default: 1,
    },
    value: {
      type: Array,
      validator: arr => arr.every(v => ['string', 'number'].includes(typeof v)),
      default: () => [],
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    height: {
      type: [String, Number],
      default: 30,
    },
  },
  data: () => ({
    uid: uniqueId('trendline-gradient_'),
    lastLength: 0,
  }),
  computed: {
    parsedWidth () {
      return Number(this.width)
    },
    totalValues () {
      return this.value.length
    },
    boundary () {
      const padding = Number(this.padding)

      return {
        minX: 0,
        maxX: this.parsedWidth,
        minY: padding,
        maxY: this.parsedHeight - padding,
      }
    },
    normalizedValues () {
      return this.value.map(item => Number(item))
    },
    isFlatLine () {
      const values = this.normalizedValues
      return !!values.length && values.every(v => v === values[0])
    },
    parsedHeight () {
      if (this.isFlatLine) return this.lineWidth
      return parseInt(this.height, 10)
    },
    radius () {
      return this.smooth ? 6 : Number(this.smooth)
    },
    points () {
      return genPoints(this.normalizedValues, this.boundary)
    },
  },
  methods: {
    genPath (fill) {
      return genPath(this.points, this.radius, fill, this.parsedHeight)
    },
  },
}
</script>

<style lang="scss" scoped>
.trendline {
  display: block;
}
</style>
