<template>
  <div class="welcome bg--color-02">
    <top-section
      class="welcome__top-section"
    />

    <activities-section
      v-if="activities.length"
      :activities="activities"
      class="welcome__activities-section"
    />

    <new-listings-section
      v-if="justListings.length"
      :title="$t('welcome.newListingsSection.justListings')"
      :listings="justListings"
      bg-class="bg--color-11"
      class="welcome__just-listings-section"
    />

    <new-listings-section
      v-if="upcomingListings.length"
      :title="$t('welcome.newListingsSection.upcomingListings')"
      :listings="upcomingListings"
      bg-class="bg--color-01"
      class="welcome__upcoming-listings-section"
    />

    <top-new-tokens-section
      v-if="topVolume.length"
      :items="topVolume"
      class="welcome__top-new-tokens-section"
    />

    <partners-section
      class="welcome__partners-section"
    />

    <c-patex-ecosystem />
    <why-latin-america />
    <subscribe-block
      default-paddings
      :style="{ 'background-color': '#a5d4ff' }"
    />
    <easy-steps-vids />
    <our-products />
    <faq-block bg-content-color-class="bg--color-01" />
    <subscribe-block />
  </div>
</template>

<script>
import { getDefaultHeadData } from '@/lib/utils'

export default {
  name: 'Index',
  components: {
    TopSection: () => import(/* webpackMode: "eager" */ '@/components/welcome/TopSection_new.vue'),
    CPatexEcosystem: () => import(/* webpackMode: "eager" */ '@/components/welcome/CPatexEcosystem.vue'),
    EasyStepsVids: () => import(/* webpackMode: "eager" */ '@/components/welcome/EasyStepsVids.vue'),
    OurProducts: () => import(/* webpackMode: "eager" */ '@/components/welcome/OurProducts.vue'),
    WhyLatinAmerica: () => import('@/components/welcome/WhyLatinAmerica.vue'),
    FaqBlock: () => import(/* webpackMode: "eager" */ '@/components/welcome/FaqBlock.vue'),
    SubscribeBlock: () => import(/* webpackMode: "eager" */ '@/components/welcome/SubscribeBlock.vue'),
    PartnersSection: () => import(/* webpackMode: "eager" */ '@/components/welcome/PartnersSection.vue'),
    ActivitiesSection: () => import(/* webpackMode: "eager" */ '@/components/welcome/ActivitiesSection.vue'),
    TopNewTokensSection: () => import(/* webpackMode: "eager" */ '@/components/welcome/TopNewTokensSection.vue'),
    NewListingsSection: () => import(/* webpackMode: "eager" */ '@/components/welcome/new-listings/NewListingsSection.vue'),
  },
  data () {
    return {
      activities: [],
      topVolume: [],
      upcomingListings: [],
      justListings: [],
    }
  },

  async fetch () {
    const [
      { response: activities },
      { response: topNewTokens },
      { response: newListings },
    ] = await Promise.all([
      this.$api.getFrontPageActivities(),
      this.$api.getFrontPageTopNewTokens(),
      this.$api.getFrontPageUpcomingListings(),
    ])

    this.activities = activities || []
    this.topVolume = topNewTokens.topVolume || []
    this.upcomingListings = newListings.filter(l => l.listingAt > Date.now())
    this.justListings = newListings.filter(l => l.listingAt <= Date.now())
  },

  fetchOnServer: false,

  head () {
    return getDefaultHeadData({
      title: this.$t('meta.index.title'),
      description: this.$t('meta.index.description'),
      img: '/image/meta/og-index.png',
      locale: this.$i18n.locale,
    })
  },
  beforeMount () {
    this.$api.$marketSubscribe()
  },
  beforeDestroy () {
    this.$api.$marketUnsubscribe()
  },
}
</script>

<style scoped lang="scss">
.welcome {
  overflow: initial;

  &__top-section + * {
    background-color: color('color-01');
  }

  &__activities-section + * {
    background-color: color('color-02');
  }

  &__just-listings-section + * {
    background-color: color('color-11');
  }

  &__upcoming-listings-section + * {
    background-color: color('color-01');
  }

  &__partners-section + * {
    background-color: color('color-01');
  }

  &__payment-section-container {
    display: none;

    @include bp(m) {
      display: block;
    }
  }
}
</style>
