<template>
  <div
    :class="'bg--' + slide.color || 'color-02'"
    class="introducing-slider-item bor--radius-20 pl-32 pt-32 pb-32 pr-32 pr-16--l"
    @mouseenter="onHoverLink"
    @mouseleave="onUnhoverLink"
  >
    <div class="introducing-slider-item__wrapper flx flx--col">
      <span
        class="txt txt--26px txt--height-34px txt--color-04 txt--bold"
        v-html="slide.title"
      />

      <span class="txt txt--22px txt--height-30px txt--color-05 pt-4 mb-8--l">
        {{ slide.text }}
      </span>

      <div
        class="introducing-slider-item__link txt--color-05 txt--pointer"
        @click="openLink"
      >
        <!-- <start-arrow-icon /> -->
        <img src="@/assets/img/welcome/start-arrow.svg" width="45" height="16" alt="start-arrow">
      </div>
    </div>

    <img
      v-if="!isMobile && loaded && slide.gif"
      v-show="isMobile ? false : isActive"
      :width="(!loaded ? true : (isMobile ? true : !isActive)) ? '170px' : '500px'"
      :height="(!loaded ? true : (isMobile ? true : !isActive)) ? '170px' : '490px'"
      :src="slide.gif"
      alt="slide image"
      loading="lazy"
    >

    <!-- <nuxt-picture
      v-show="!loaded ? true : (isMobile ? true : !isActive)"
      :src="slide.img"
      sizes="xs:280px sm:280px md:280px md:280px lg:280px"
      width="100%"
      loading="lazy"
      alt="slide image"
    /> -->

    <img
      v-show="!loaded ? true : (isMobile ? true : !isActive) || !slide.gif"
      :width="(!loaded ? true : (isMobile ? true : !isActive)) ? '170px' : '500px'"
      :height="(!loaded ? true : (isMobile ? true : !isActive)) ? '170px' : '490px'"
      :src="slide.img"
      loading="lazy"
      alt="slide image"
    >
  </div>
</template>

<script>
export default {
  name: 'IntroducingSliderItem',
  components: {
    // StartArrowIcon: () => import('@/assets/img/welcome/start-arrow.svg?inline'),
  },
  props: {
    slide: { type: Object, required: true },
    isSliding: { type: Boolean, default: false },
    isActive: { type: Boolean, default: false },
  },
  data: () => ({ loaded: false }),
  computed: {
    isMobile () {
      if (!process.client) return true
      return this.$mq?.resize && this.$mq?.below(991)
    },
  },
  mounted () {
    this.loaded = true
  },
  methods: {
    openLink () {
      if (!this.slide.link) return
      this.localePush(this.slide.link)
    },
    onHoverLink () {
      this.$emit('onHover')
    },
    onUnhoverLink () {
      this.$emit('onUnhover')
    },
  },
}
</script>

<style lang="scss" scoped>
.introducing-slider-item {
  $root: &;

  position: relative;

  display: grid;
  gap: toRem(16px);
  min-height: toRem(240px);
  height: 100%;
  grid-template-columns: 1fr;

  @include bp(m) {
    // width: toRem(479px);
    max-width: toRem(479px);
    grid-template-columns: 1fr toRem(170px);
  }

  @include bp(0, 600px) {
    // max-width: 356px;
    max-width: calc(100vw - 60px);
  }

  @include bp(0, m) {
    height: 100%;

    img {
      justify-self: end;
    }
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;

    @include bp(m) {
      width: 100%;
      min-width: toRem(170px);
      min-height: toRem(170px);
    }
  }

  &__wrapper {
    height: 100%;
  }

  &__link {
    background: rgba(255, 255, 255, 0.7);
    border-radius: toRem(230px);

    height: toRem(36px);
    width: toRem(80px);

    padding: auto toRem(10px);
    margin-top: toRem(14px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 0.25s;

    @include bp(m) {
      margin-top: auto;
    }

    img {
      max-width: toRem(45px) !important;
      max-height: toRem(16px) !important;
      min-width: unset;
      min-height: unset;
    }
  }

  @include bp(m) {
    &:hover #{$root}__link {
      width: toRem(90px);
    }
  }
}
</style>
