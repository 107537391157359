<template>
  <section
    v-if="items.length"
    class="top-new-tokens-section"
  >
    <div class="container-wrapper bg--color-02">
      <div class="top-new-tokens-section__container container-new">
        <div class="top-new-tokens-section__head">
          <div class="top-new-tokens-section__title">
            {{ $t('welcome.topNewTokensSection.title') }}
          </div>
          <div class="top-new-tokens-section__description">
            {{ $t('welcome.topNewTokensSection.description') }}
          </div>
        </div>

        <div
          v-if="topItem"
          class="top-new-tokens-section__top"
        >
          <div class="top-new-tokens-section__top-head">
            <icon-star />
            {{ topItem.pairName }}
          </div>

          <div class="top-new-tokens-section__top-content">
            <span>{{ `${$t('welcome.topNewTokensSection.weekVolume')}:` }}</span>
            <b>{{ topItem.formattedVolume }}</b>
          </div>
        </div>

        <div class="top-new-tokens-section__carousel-wrapper">
          <client-only>
            <hooper
              ref="carousel"
              :key="carouselKey"
              :settings="carouselSettings"
              :auto-play="carouselIsEnabled"
              style="height: auto;"
              class="top-new-tokens-section__carousel"
              @slide="onSlide"
            >
              <slide
                v-for="(item, index) in normalizedItems"
                :key="index"
                :duration="5000"
              >
                <div class="top-new-tokens-section__carousel-slide">
                  <div class="carousel-slide sha--03">
                    <div class="carousel-slide__item">
                      <div class="carousel-slide__item-label">
                        {{ $t('welcome.topNewTokensSection.project') }}
                      </div>
                      <div class="carousel-slide__item-value carousel-slide__ticker">
                        <icon-star v-if="item.isTop" />
                        {{ item.projectName }}
                      </div>
                    </div>
                    <div class="carousel-slide__item">
                      <div class="carousel-slide__item-label">
                        {{ $t('welcome.topNewTokensSection.market') }}
                      </div>
                      <div class="carousel-slide__item-value">
                        {{ item.pairName }}
                      </div>
                    </div>
                    <div class="carousel-slide__item">
                      <div class="carousel-slide__item-label">
                        {{ $t('welcome.topNewTokensSection.weekVolume') }}
                      </div>
                      <div class="carousel-slide__item-value">
                        {{ item.formattedVolume }}
                      </div>
                    </div>
                    <div class="carousel-slide__item">
                      <div class="carousel-slide__item-label">
                        {{ $t('welcome.topNewTokensSection.weekChange') }}
                      </div>
                      <div
                        :class="['carousel-slide__item-value', {
                          'txt--green': item.change > 0,
                          'txt--red': item.change < 0
                        }]"
                      >
                        {{ item.formattedChange }}
                      </div>
                    </div>
                    <div class="carousel-slide__item carousel-slide__dynamics">
                      <div class="carousel-slide__item-label">
                        {{ $t('welcome.topNewTokensSection.dynamics') }}
                      </div>

                      <trendline
                        class="carousel-slide__trendline"
                        :value="item.klineData"
                        :color-class="item.change > 0 ? 'txt--green' : item.change < 0 ? 'txt--red' : 'txt--color-04'"
                        :width="240"
                        :height="60"
                      />
                    </div>

                    <div class="carousel-slide__button">
                      <exc-button
                        color="main"
                        size="s"
                        outline
                        :to="localePath(`/exchange/${item.pairName}`)"
                      >
                        {{ $t('welcome.topNewTokensSection.trade') }}
                      </exc-button>
                    </div>
                  </div>
                </div>
              </slide>
            </hooper>
          </client-only>

          <div
            v-if="carouselIsEnabled"
            class="top-new-tokens-section__pagination"
          >
            <div
              v-for="(slide, index) in normalizedItems"
              :key="index"
              :class="['top-new-tokens-section__pagination-item', {
                'top-new-tokens-section__pagination-item--active': index === carouselSlide
              }]"
            />
          </div>
        </div>

        <div class="table sha--03 top-new-tokens-section__table">
          <div class="table__head">
            <div
              v-for="(col, index) in tableHead"
              :key="index"
              class="table__head-col"
            >
              {{ col }}
            </div>
          </div>

          <div class="table__content">
            <div
              v-for="(item, index) in normalizedItems"
              :key="index"
              class="table__item"
            >
              <div class="table__item-value table__ticker">
                <icon-star v-if="item.isTop" />
                {{ item.projectName }}
              </div>

              <div class="table__item-value">
                {{ item.pairName }}
              </div>

              <div class="table__item-value">
                {{ item.formattedVolume }}
              </div>

              <div
                :class="['table__item-value', {
                  'txt--green': item.change > 0,
                  'txt--red': item.change < 0
                }]"
              >
                {{ item.formattedChange }}
              </div>

              <div class="table__trendline-wrapper">
                <trendline
                  class="table__trendline"
                  :value="item.klineData"
                  :color-class="item.change > 0 ? 'txt--green' : item.change < 0 ? 'txt--red' : 'txt--color-04'"
                  :width="240"
                  :height="40"
                />
              </div>

              <exc-button
                color="main"
                size="xs"
                outline
                full-width
                :to="localePath(`/exchange/${item.pairName}`)"
                class="table__button"
              >
                {{ $t('welcome.topNewTokensSection.trade') }}
              </exc-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ExcButton } from '@/components/common'
import { Hooper, Slide } from 'hooper-cnb'
import { formatShortNumber } from '~/lib/utils'

export default {
  name: 'TopNewTokensSection',

  components: {
    Hooper,
    Slide,
    ExcButton,
    // @ts-ignore
    IconStar: () => import('@/assets/img/welcome/top-new-tokens-section/star.svg?inline'),
    Trendline: () => import(/* webpackMode: "eager" */ '@/components/imgs/Trendline.vue'),
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      carouselKey: 0,
      carouselSlide: 0,
    }
  },

  computed: {
    tableHead () {
      return [
        this.$t('welcome.topNewTokensSection.project'),
        this.$t('welcome.topNewTokensSection.market'),
        this.$t('welcome.topNewTokensSection.weekVolume'),
        this.$t('welcome.topNewTokensSection.weekChange'),
        this.$t('welcome.topNewTokensSection.dynamics'),
        this.$t('welcome.topNewTokensSection.trade'),
      ]
    },
    normalizedItems () {
      return this.items.map((item, index) => {
        const { volumeInMoney, changeValue, klineData, projectName } = item
        const [left, right] = item.name.split('_')
        const change = Number(changeValue)

        return {
          ticker: left,
          projectName,
          pairName: `${left}/${right}`,
          formattedVolume: `$${formatShortNumber(Number(volumeInMoney))}`,
          change,
          formattedChange: `${change}%`,
          isTop: index === 0,
          klineData: (klineData || []).map(v => v.lastprice),
        }
      })
    },
    topItem () {
      return this.normalizedItems[0]
    },
    carouselItemsToShow () {
      return [
        [0, 1],
        [768, 2],
        [992, 3],
        [1280, 4],
      ].map(([breakpoint, itemsToShow]) => ({
        isActive: this.$mq?.resize && this.$mq?.above(breakpoint),
        itemsToShow,
      })).findLast(({ isActive }) => isActive).itemsToShow
    },
    carouselIsEnabled () {
      return this.normalizedItems.length > this.carouselItemsToShow
    },
    carouselSettings () {
      return {
        wheelControl: false,
        itemsToShow: this.carouselItemsToShow,
        mouseDrag: this.carouselIsEnabled,
        touchDrag: this.carouselIsEnabled,
        infiniteScroll: this.carouselIsEnabled,
      }
    },
  },

  watch: {
    carouselSettings: {
      handler () {
        this.carouselKey++
      },
      deep: true,
    },
  },

  methods: {
    onSlide ({ currentSlide }) {
      const firstIndex = 0
      const lastIndex = this.normalizedItems.length - 1

      if (currentSlide > lastIndex) this.carouselSlide = firstIndex
      else if (currentSlide < firstIndex) this.carouselSlide = lastIndex
      else this.carouselSlide = currentSlide
    },
  },
}
</script>

<style lang="scss" scoped>
.top-new-tokens-section {
  &__container {
    @include bp(l) {
      display: grid;
      grid-template-areas:
        'head top'
        'table table';
      grid-template-columns: minmax(0, 1fr) auto;
      align-items: center;
      gap: toRem(28px) toRem(20px);
    }
  }

  &__head {
    margin-bottom: toRem(20px);

    @include bp(l) {
      grid-area: head;
      margin: 0;
    }
  }

  &__title {
    font-size: toRem(36px);
    line-height: toRem(44px);
    font-weight: 700;
    margin-bottom: toRem(18px);
    color: color('color-04');

    @include bp(l) {
      font-size: toRem(44px);
      line-height: toRem(58px);
    }
  }

  &__description {
    font-size: toRem(22px);
    line-height: toRem(30px);
    color: color('color-03');
  }

  &__top {
    border-radius: toRem(20px);
    background-color: color('color-11');
    padding: toRem(20px);
    color: color('color-05');
    margin-bottom: toRem(24px);

    @include bp(l) {
      grid-area: top;
      margin: 0;
    }
  }

  &__top-head {
    display: flex;
    gap: toRem(4px);
    margin-bottom: toRem(12px);
    font-size: toRem(22px);
    line-height: toRem(30px);
    font-weight: 700;

    svg {
      display: block;
      width: toRem(30px);
      height: toRem(30px)
    }
  }

  &__top-content {
    display: flex;
    flex-wrap: wrap;
    gap: 0 toRem(12px);
    font-size: toRem(20px);
    line-height: toRem(24px);
  }

  &__carousel-wrapper {
    @include bp(l) {
      display: none;
    }
  }

  &__carousel {
    width: calc(100% + #{toRem(24px)}) !important;
    margin: toRem(-12px);
  }

  &__carousel-slide {
    padding: toRem(12px);
  }

  &__pagination {
    display: flex;
    gap: toRem(7px);
    align-items: center;
    justify-content: center;
    height: toRem(10px);
    margin-top: toRem(16px);
  }

  &__pagination-item {
    width: toRem(6px);
    height: toRem(6px);
    background: #d1d1d1;
    border-radius: 50%;
    transition: 0.2s;

    &--active {
      width: toRem(10px);
      height: toRem(10px);
      background: color('color-05');
    }
  }

  &__table {
    display: none;

    @include bp(l) {
      display: block;
      grid-area: table;
    }
  }
}

.carousel-slide {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: toRem(16px);
  padding: toRem(20px) toRem(16px);
  border-radius: toRem(12px);
  background-color: #fff;

  &__item {
    color: color('color-04');
  }

  &__item-label {
    font-size: toRem(14px);
    line-height: toRem(18px);
    color: color('color-03');
    margin-bottom: toRem(4px);
  }

  &__item-value {
    font-size: toRem(14px);
    line-height: toRem(30px);
    font-weight: 500;
  }

  &__ticker {
    display: flex;
    gap: toRem(4px);

    svg {
      width: toRem(30px);
      height: toRem(30px);
    }
  }

  &__dynamics {
    grid-column: 1/3;
  }

  &__button {
    display: flex;
    grid-column: 1/3;
    margin-top: toRem(8px);
  }

  &__trendline {
    max-width: toRem(240px)
  }
}

.table {
  --grid-template-columns: 150fr 200fr 200fr 200fr 300fr #{toRem(140px)};
  --grid-gap: #{toRem(20px)};

  background-color: #fff;
  border-radius: toRem(12px);
  padding: toRem(20px) toRem(24px);

  &__head {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    gap: var(--grid-gap);
    background-color: rgba(145, 148, 159, 0.04);
    border-radius: toRem(6px) toRem(6px) 0 0;
    padding: toRem(20px) toRem(24px);
    border-bottom: 1px solid rgba(145, 148, 159, 0.20);
  }

  &__head-col {
    font-size: toRem(14px);
    line-height: toRem(18px);
    color: color('color-03');
  }

  &__item {
    display: grid;
    grid-template-columns: var(--grid-template-columns);
    gap: var(--grid-gap);
    align-items: center;
    padding: toRem(16px) toRem(24px);
    min-height: toRem(100px);
    color: color('color-04');

    &:not(:last-child) {
      border-bottom: 1px solid rgba(145, 148, 159, 0.20);
    }
  }

  &__item-value {
    font-size: toRem(14px);
    line-height: toRem(18px);
    font-weight: 500;
  }

  &__ticker {
    display: flex;
    align-items: center;
    gap: toRem(4px);

    svg {
      width: toRem(30px);
      height: toRem(30px);
    }
  }

  &__trendline {
    max-width: toRem(240px);
  }
}
</style>
