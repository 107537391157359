<template>
  <div class="new-listings-card sha--03">
    <div class="new-listings-card__container">
      <div class="new-listings-card__image-wrapper">
        <img
          class="new-listings-card__image"
          :src="listing.image"
          :alt="listing.name"
        >
      </div>

      <div class="new-listings-card__content">
        <div class="new-listings-card__title">
          <span>{{ listing.name }}</span>
          <span class="txt--uppercase txt--color-03">({{ listing.ticker }})</span>
        </div>

        <div class="new-listings-card__time">
          <div class="txt--16px txt--height-20px txt--color-04 txt--weight-700 mb--6px">
            {{ $t('welcome.newListingsCard.listingTime') }}:
          </div>
          <div class="txt--14px txt--height-18px txt--color-03">
            {{ listing.listingAt | formatDate('DD-MM-YYYY') }}
          </div>
        </div>
      </div>
    </div>

    <div class="countdown new-listings-card__countdown">
      <div class="countdown__title">
        {{ countdownData.label }}:
      </div>

      <div class="countdown__container">
        <div
          v-for="(item, index) in countdownData.items"
          :key="index"
          class="countdown__item"
        >
          <div class="countdown__value">
            {{ item.value }}
          </div>

          <div class="countdown__label">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewListingsCard',

  components: {
    /*  */
  },

  props: {
    listing: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      isUpcomingListing: false,
      interval: null,
      countdown: {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
    }
  },

  computed: {
    countdownData () {
      return {
        label: this.isUpcomingListing
          ? this.$t('welcome.newListingsCard.countdownToLabel')
          : this.$t('welcome.newListingsCard.countdownFromLabel'),
        items: [
          {
            label: 'D',
            value: this.countdown.days,
          },
          {
            label: 'H',
            value: this.countdown.hours,
          },
          {
            label: 'M',
            value: this.countdown.minutes,
          },
          {
            label: 'S',
            value: this.countdown.seconds,
          },
        ],
      }
    },
  },

  mounted () {
    this.isUpcomingListing = this.listing.listingAt > Date.now()

    this.updateCountdown()
    this.interval = setInterval(this.updateCountdown, 1000)
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    updateCountdown () {
      const now = Date.now()
      const diff = Math.abs(this.listing.listingAt - now)

      if (this.isUpcomingListing && diff <= 0) {
        clearInterval(this.interval)
        return
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((diff % (1000 * 60)) / 1000)

      this.countdown = {
        days: days.toString().padStart(2, '0'),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.new-listings-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: toRem(20px);
  background-color: color('color-01');

  &__container {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    position: relative;
  }

  &__image-wrapper {
    position: relative;
    aspect-ratio: 1;
    background-image: url('@/assets/img/welcome/new-listings-card/figure.webp');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__image {
    display: block;
    width: toRem(94px);
    height: toRem(94px);
    object-fit: contain;
    position: absolute;
    top: 36%;
    left: 36%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;

    @include bp(l) {
      width: toRem(110px);
      height: toRem(110px);
    }
  }

  &__content {
    padding: toRem(24px) toRem(24px) toRem(24px) toRem(10px);
  }

  &__title {
    display: flex;
    flex-wrap: wrap;
    gap: toRem(4px);
    font-size: toRem(20px);
    line-height: toRem(24px);
    font-weight: 700;
    margin-bottom: toRem(10px);

    @include bp(l) {
      font-size: toRem(22px);
      line-height: toRem(30px);
    }
  }

  &__time {
    display: flex;
    flex-direction: column;
    gap: toRem(6px);
  }

  &__countdown {
    padding: 0 toRem(24px) toRem(24px);
  }
}

.countdown {
  &__title {
    font-size: toRem(16px);
    line-height: toRem(20px);
    font-weight: 700;
    color: color('color-04');
    text-align: center;
    margin-bottom: toRem(12px);
  }

  &__container {
    display: flex;
    justify-content: center;
    gap: toRem(24px);
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      &::before, &::after {
        content: '';
        display: block;
        width: toRem(4px);
        height: toRem(4px);
        border-radius: 50%;
        background-color: color('color-11');
        position: absolute;
        right: toRem(-14px);
      }

      &::before {
        top: toRem(14px);
      }

      &::after {
        top: toRem(22px);
      }
    }
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: toRem(40px);
    height: toRem(40px);
    border-radius: toRem(12px);
    background-color: color('color-11');
    font-size: toRem(16px);
    font-weight: 600;
    color: color('color-04');
    text-align: center;
    margin-bottom: toRem(4px);
  }

  &__label {
    font-size: toRem(12px);
    line-height: toRem(14px);
    color: color('color-03');
    text-align: center;
  }
}
</style>
