<template>
  <section
    v-if="sortedListings.length"
    class="new-listings-section"
  >
    <div :class="['container-wrapper new-listings-section__container-wrapper', bgClass]">
      <div class="new-listings-section__container container-new">
        <div
          v-if="title"
          class="new-listings-section__title"
        >
          {{ title }}
        </div>

        <div class="new-listings-section__carousel-wrapper">
          <client-only>
            <hooper
              ref="carousel"
              :key="carouselKey"
              :settings="carouselSettings"
              :auto-play="carouselIsEnabled"
              style="height: auto;"
              class="new-listings-section__carousel"
              @slide="onSlide"
            >
              <slide
                v-for="(item, index) in sortedListings"
                :key="index"
                :duration="5000"
              >
                <div class="new-listings-section__carousel-slide">
                  <new-listings-card
                    :listing="item"
                    class="new-listings-section__card"
                  />
                </div>
              </slide>
            </hooper>
          </client-only>

          <div
            v-if="carouselIsEnabled"
            class="new-listings-section__pagination"
          >
            <div
              v-for="(item, index) in sortedListings"
              :key="index"
              :class="['new-listings-section__pagination-item', {
                'new-listings-section__pagination-item--active': index === carouselSlide
              }]"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper-cnb'

export default {
  name: 'NewListingsSection',

  components: {
    Hooper,
    Slide,
    NewListingsCard: () => import(/* webpackMode: "eager" */ '@/components/welcome/new-listings/NewListingsCard.vue'),
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    listings: {
      type: Array,
      required: true,
    },
    bgClass: {
      type: String,
      default: 'bg--color-01',
    },
  },

  data () {
    return {
      carouselKey: 0,
      carouselSlide: 0,
    }
  },

  computed: {
    sortedListings () {
      const dateNow = Date.now()

      const getDateDiff = ({ listingAt }) => Math.abs(listingAt - dateNow)

      return [...this.listings].sort((a, b) => getDateDiff(a) - getDateDiff(b))
    },
    itemsToShow () {
      return [
        [0, 1],
        [768, 2],
        /* [992, 2], */
        [1280, 3],
      ].map(([breakpoint, itemsToShow]) => ({
        isActive: this.$mq?.resize && this.$mq?.above(breakpoint),
        itemsToShow,
      })).findLast(({ isActive }) => isActive).itemsToShow
    },
    carouselIsEnabled () {
      return this.sortedListings.length > this.itemsToShow
    },
    carouselSettings () {
      return {
        wheelControl: false,
        itemsToShow: this.itemsToShow,
        mouseDrag: this.carouselIsEnabled,
        touchDrag: this.carouselIsEnabled,
        infiniteScroll: this.carouselIsEnabled,
      }
    },
  },

  watch: {
    carouselSettings: {
      handler () {
        this.carouselKey++
      },
      deep: true,
    },
  },

  methods: {
    onSlide ({ currentSlide }) {
      const firstIndex = 0
      const lastIndex = this.sortedListings.length - 1

      if (currentSlide > lastIndex) this.carouselSlide = firstIndex
      else if (currentSlide < firstIndex) this.carouselSlide = lastIndex
      else this.carouselSlide = currentSlide
    },
  },
}
</script>

<style lang="scss" scoped>
.new-listings-section {
  $root: &;

  &__title {
    font-size: toRem(36px);
    line-height: toRem(44px);
    font-weight: 700;
    color: color('color-04');
    margin-bottom: toRem(20px);

    @include bp(l) {
      font-size: toRem(44px);
      line-height: toRem(58px);
      margin-bottom: toRem(40px);
    }
  }

  &__carousel {
    width: calc(100% + #{toRem(24px)}) !important;
    margin: toRem(-12px);

    &::v-deep .hooper-slide {
      height: auto;
    }
  }

  &__carousel-slide {
    height: 100%;
    padding: toRem(12px);
  }

  &__card {
    height: 100%;
  }

  &__pagination {
    display: flex;
    gap: toRem(7px);
    align-items: center;
    justify-content: center;
    height: toRem(10px);
    margin-top: toRem(16px);
  }

  &__pagination-item {
    width: toRem(6px);
    height: toRem(6px);
    background-color: #d1d1d1;
    border-radius: 50%;
    transition: 0.2s;

    #{$root}__container-wrapper.bg--color-11 & {
      background-color: color('color-01');
    }

    &--active {
      width: toRem(10px);
      height: toRem(10px);
      background-color: color('color-05') !important;
    }
  }
}
</style>
