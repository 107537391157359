<template>
  <div
    :class="[
      bgClass || 'bg--color-02',
      {
        'default-paddings': defaultPaddings,
      },
    ]"
    class="subscribe-block"
  >
    <div class="subscribe-block__container bg--color-09">
      <div class="subscribe-block__cols container-new">
        <div class="subscribe-block__text-block flx flx--col">
          <span
            class="txt txt--36px txt--42px--m txt--height-44px txt--height-50px--m txt--color-04 txt--weight-600"
          >
            {{ $t('welcome.subscribeSection.title') }}
          </span>

          <span
            class="txt txt--16px txt--22px--m txt--height-20px txt--height-28px--m txt--color-04 pt-4"
          >
            {{ $t('welcome.subscribeSection.desc') }}
          </span>
        </div>

        <div class="subscribe-block__form">
          <exc-input
            v-model="$v.email.$model"
            :placeholder="$t('welcome.subscribeSection.email')"
            size="s"
            :field="$v.email"
            :optional-errors="{
              ...mix_getServerError('email'),
              ...customErrors,
            }"
            name="email"
            class="subscribe-block__input"
          />

          <exc-button
            size="s"
            :disabled="$v.email.$model === '' || $v.$invalid || isLoggedIn"
            @click="subscribe"
          >
            {{ $t('welcome.subscribeSection.subscribe') }}
          </exc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcInput, ExcButton } from '@/components/common'
import { maxLength, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import AuthMixinCaptcha from '@/mixins/auth/AuthMixinCaptcha'
import AuthFormSubmit from '@/mixins/auth/AuthFormSubmit'
import { serverFormMixin } from '@/mixins/serverFormMixin'
import { mapState } from 'vuex'
import { EMAIL } from '~/constants'

export default {
  components: {
    ExcInput,
    ExcButton,
  },
  mixins: [AuthMixinCaptcha, AuthFormSubmit, validationMixin, serverFormMixin],
  props: {
    bgClass: { type: String, default: 'bg--color-02' },
    defaultPaddings: { type: Boolean, default: false },
  },
  data () {
    return {
      email: '',
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.auth.loggedIn,
    }),
    customErrors () {
      return {
        maxLength: this.$t('validators.maxLength', { max: 255 }),
        email: this.$t('validators.email'),
      }
    },
  },
  methods: {
    subscribe () {
      if (process.client && !this.isLoggedIn) {
        localStorage.setItem(EMAIL, this.email)
        this.localePush('/register')
      }
      // try {
      //   const { errors, response } = await this.$api.subscriptionSaveEmail({
      //     email: this.email,
      //     type_id: 1,
      //   })
      //   if (errors) {
      //     this.$notify({
      //       type: 'error',
      //       text: this.$t(errors.email[0]),
      //     })
      //     return
      //   }
      //   if (response) {
      //     this.$notify({
      //       type: 'success',
      //       text: response.response.success,
      //     })
      //   }
      // } catch (e) {
      //   console.log(e)
      // }
    },
  },
  validations () {
    return this.mix_validationsServerWrapper({
      email: {
        maxLength: maxLength(255),
        email,
      },
      // mix_captchaData: {},
    })
  },
}
</script>

<style lang="scss" scoped>
.subscribe-block {
  $root: &;

  &__container {
    border-radius: toRem(20px) toRem(20px) 0 0;
    padding: toRem(20px) 0 toRem(40px) 0;

    @include bp(m) {
      padding: toRem(60px) 0 toRem(120px) 0;
      border-radius: toRem(60px) toRem(60px) 0 0;
    }
  }

  &.default-paddings {
    #{$root}__container {
      padding: toRem(20px) 0 toRem(20px) 0;

      @include bp(m) {
        padding: toRem(60px) 0 toRem(60px) 0;
      }
    }
  }

  &__cols {
    display: grid;
    grid-template-columns: 1fr;
    gap: toRem(24px);

    @include bp(m) {
      grid-template-columns: 2fr 1fr;
      gap: toRem(60px);
    }
  }

  &__input ::v-deep input {
    background-color: white;
  }
}
</style>
