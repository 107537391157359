<template>
  <div
    class="faq-block"
    :class="{
      [bgColorClass]: bgColorClass,
    }"
  >
    <div
      :class="{
        [bgContentColorClass]: bgContentColorClass,
      }"
      class="container-wrapper"
    >
      <div class="faq-block__cols container-new">
        <div class="faq-block__text-block flx flx--col">
          <span
            class="txt txt--36px txt--44px--m txt--height-44px txt--height-58px--m txt--color-04 txt--weight-600"
          >
            Q&A
          </span>

          <span
            class="txt txt--16px txt--22px--m txt--height-20px txt--height-30px--m txt--color-03 pt-4"
          >
            {{ $t('welcome.faq.title') }}
          </span>

          <div class="faq-block__btns mt-16 mt-32--m">
            <exc-button
              :to="localePath(loggedIn ? '/support' : '/login')"
              outline
              size="s"
              full-width
              class="faq-block__btn"
            >
              {{ $t('welcome.faq.contactSupport') }}
            </exc-button>

            <exc-button
              :to="localePath('/faq')"
              size="s"
              full-width
              class="faq-block__btn"
            >
              FAQ
            </exc-button>
          </div>
        </div>

        <div class="faq-block__list">
          <welcome-faq-list :items="faqs" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcButton } from '@/components/common'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ExcButton,
    WelcomeFaqList: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/FaqComponents/WelcomeFaqList.vue'
      ),
  },
  props: {
    bgColorClass: { type: String, default: 'bg--color-01' },
    bgContentColorClass: { type: String, default: 'bg--color-02' },
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.loggedIn,
    }),
    ...mapGetters('faq', ['getFaqsWelcome']),
    faqs () {
      return this.getFaqsWelcome()
    },
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.fetchFaqItems()
      },
      deep: true,
    },
  },
  created () {
    if (!process.client) return

    this.fetchFaqItems().finally(() => {})
  },
  methods: {
    ...mapActions({
      fetchFaqItems: 'faq/getFaqListForWelcome',
    }),
  },
}
</script>

<style lang="scss" scoped>
.faq-block {
  // &__container {
  //   border-radius: toRem(20px) toRem(20px) 0 0;

  //   @include bp(m) {
  //     border-radius: toRem(60px) toRem(60px) 0 0;
  //   }
  // }

  &__cols {
    display: grid;
    grid-template-columns: 1fr;
    gap: toRem(24px);

    @include bp(m) {
      grid-template-columns: 3fr 5fr;
      gap: toRem(60px);
    }
  }

  &__text-block {
    @include bp(m) {
      position: sticky;
      top: toRem(100px);
      height: fit-content;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    gap: toRem(10px);

    @include bp(l) {
      flex-direction: row;
    }
  }

  &__btn {
    @include bp(l) {
      max-width: fit-content;
    }

    ::v-deep span {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &__input ::v-deep input {
    background-color: white;
  }
}
</style>
