<template>
  <exc-loader v-if="loading" class="txt--main" />

  <div v-else>
    <welcome-faq-block
      v-for="(group, i) in faqItems"
      :key="group.id || `key_${i}`"
      :posts="group.posts"
    />
  </div>
</template>

<script>
import { ExcLoader } from '@/components/common'
import { mapActions, mapGetters } from 'vuex'

const GROUP_ID = 8

export default {
  name: 'WelcomeFaqList',
  components: {
    ExcLoader,
    WelcomeFaqBlock: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/FaqComponents/WelcomeFaqBlock.vue'
      ),
  },
  props: {
    items: {
      // groups with posts
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('faq', ['getFaqs']),
    faqItems () {
      return this.items.filter(group => group.id === GROUP_ID)
      // return this.getFaqs().filter(group => group.id === GROUP_ID)
      // return [
      //   {
      //     posts: [
      //       {
      //         id: 6,
      //         title: this.$t('welcome.faq.questions[0]'),
      //         content:
      //           'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores non, voluptas, excepturi enim amet architecto doloribus illo cupiditate corporis error quae veniam molestias ut odio minus modi quis id tempore.',
      //       },
      //       {
      //         id: 12,
      //         title: this.$t('welcome.faq.questions[1]'),
      //         content:
      //           'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores non, voluptas, excepturi enim amet architecto doloribus illo cupiditate corporis error quae veniam molestias ut odio minus modi quis id tempore.',
      //       },
      //       {
      //         id: 17,
      //         title: this.$t('welcome.faq.questions[2]'),
      //         content:
      //           'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores non, voluptas, excepturi enim amet architecto doloribus illo cupiditate corporis error quae veniam molestias ut odio minus modi quis id tempore.',
      //       },
      //       {
      //         id: 22,
      //         title: this.$t('welcome.faq.questions[3]'),
      //         content:
      //           'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores non, voluptas, excepturi enim amet architecto doloribus illo cupiditate corporis error quae veniam molestias ut odio minus modi quis id tempore.',
      //       },
      //     ],
      //   },
      // ]
    },
  },
  created () {
    // this.loading = true
    // this.actionGetFaq()
    //   .finally(() => {
    //     this.loading = false
    //   })
  },
  methods: {
    ...mapActions({
      actionGetFaq: 'faq/actionGetFaq',
    }),
  },
}
</script>
