<template>
  <div
    :class="[opened ? 'bg--color-01' : 'bg--color-01', classes]"
    class="welcome-faq-item txt--color-04"
  >
    <rxd-block-heading-row
      size="l"
      no-wrap
      class="welcome-faq-item__toggler p-0 bor--radius-16"
      @click.native="openPostHandler"
    >
      <template #first>
        <p class="welcome-faq-item__title txt--color-04 txt--weight-600 txt--22px txt--26px--l txt--height-34px--m mb-0">
          {{ post.title }}
        </p>
      </template>

      <template #right>
        <span
          :class="{ 'welcome-faq-item__icon--opened': opened }"
          class="welcome-faq-item__icon"
        >
          <!-- <dropdown-arrow /> -->
          <img
            :src="require('@/assets/icons/welcome/dropdown-arrow.svg')"
            width="37px"
            height="37px"
            loading="lazy"
            alt="faq"
          >
        </span>
      </template>
    </rxd-block-heading-row>

    <div v-show-slide="opened" class="welcome-faq-item__content">
      <div
        class="
          welcome-faq-item__content-item
          txt--18px txt--22px--l
          txt--height-26px--l
          txt--height-30px--l
          txt--color-03
        "
        v-html="content"
      />
    </div>
  </div>
</template>

<script>
import { RxdBlockHeadingRow } from '@/components/common'

export default {
  name: 'WelcomeFaqItem',
  components: {
    RxdBlockHeadingRow,
    // DropdownArrow: () =>
    //   import(
    //     /* webpackMode: "eager" */ '@/assets/icons/welcome/dropdown-arrow.svg?inline'
    //   ),
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      opened: false,
    }
  },
  computed: {
    classes () {
      return {
        'welcome-faq-item--opened': this.opened,
      }
    },
    content () {
      return (this.post?.content || '').replace(/&nbsp;/g, ' ')
    },
  },
  methods: {
    openPostHandler () {
      this.opened = !this.opened
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome-faq-item {
  border-radius: toRem(20px);
  margin-bottom: toRem(18px);
  transition: 0.3s;
  box-shadow: 0px 1px 20px 0px #1618220F;

  @include bp(m) {
    border-radius: toRem(80px);
  }

  @include bp(l) {
    border-radius: toRem(100px);
  }

  &--opened {
    border-radius: toRem(30px);
  }

  ::v-deep .exc-block-heading-row__container {
    background: none;
    border: none;
  }

  &__toggler {
    padding: toRem(12px) toRem(8px);

    @include bp(m) {
      padding: toRem(24px);
    }

    @include bp(l) {
      padding: toRem(28px);
    }

    cursor: pointer;
  }

  &__icon {
    transition: 0.3s;
    transform: rotate(0deg);

    &--opened {
      transform: rotate(45deg);
    }
  }

  &__content {
    &-item {
      padding: toRem(12px) toRem(8px);

      @include bp(m) {
        padding: 0 toRem(24px) toRem(24px);
      }

      @include bp(l) {
        padding: 0 toRem(28px) toRem(28px);
      }
    }

    ::v-deep img {
      max-width: 100%;
      // max-width: 100% !important;
      // max-width: calc(100vw - 40px);
      height: auto;
    }
    ::v-deep p {
      margin: 0;
    }
  }

  &__title {
    word-break: break-word;
  }
}
</style>
