<template>
  <section class="partners-section">
    <div class="container-wrapper bg--color-01">
      <div class="partners-section__container container-new">
        <div class="partners-section__title">
          {{ $t('welcome.partnersSection.title') }}
        </div>

        <div class="partners-section__items">
          <div
            v-for="item in items"
            :key="item.id"
            :class="['partners-section__item', { 'partners-section__item--marked': item.marked }]"
          >
            <img
              :src="item.image"
              :alt="item.id"
              loading="lazy"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PartnersSection',

  computed: {
    items () {
      return [
        {
          id: 'liquid-lines',
          image: require('@/assets/img/welcome/partners-section/liquid-lines.webp'),
          marked: true,
        },
        {
          id: 'promoj',
          image: require('@/assets/img/welcome/partners-section/promoj.webp'),
          marked: true,
        },
        {
          id: 'cointelegraph',
          image: require('@/assets/img/welcome/partners-section/cointelegraph.webp'),
          marked: true,
        },
        {
          id: 'beliquid',
          image: require('@/assets/img/welcome/partners-section/beliquid.webp'),
          marked: true,
        },
        {
          id: 'moon-pay',
          image: require('@/assets/img/welcome/partners-section/moon-pay.webp'),
        },
        {
          id: 'advcash',
          image: require('@/assets/img/welcome/partners-section/advcash.webp'),
        },
        {
          id: 'chainalysis',
          image: require('@/assets/img/welcome/partners-section/chainalysis.webp'),
        },
        {
          id: 'certik',
          image: require('@/assets/img/welcome/partners-section/certik.webp'),
        },
        {
          id: 'coinzilla',
          image: require('@/assets/img/welcome/partners-section/coinzilla.webp'),
        },
        {
          id: 'simplex',
          image: require('@/assets/img/welcome/partners-section/simplex.webp'),
        },
        {
          id: 'onfido',
          image: require('@/assets/img/welcome/partners-section/onfido.webp'),
        },
        {
          id: 'coin-market-cap',
          image: require('@/assets/img/welcome/partners-section/coin-market-cap.webp'),
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.partners-section {
  &__title {
    font-size: toRem(36px);
    line-height: toRem(44px);
    font-weight: 700;
    margin-bottom: toRem(20px);
    color: color('color-04');

    @include bp(l) {
      font-size: toRem(44px);
      line-height: toRem(58px);
      text-align: center;
      margin-bottom: toRem(40px);
    }
  }

  &__items {
    --columns: 2;
    display: grid;
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    gap: toRem(24px);

    @include bp(s) {
      --columns: 3;
    }

    @include bp(m) {
      --columns: 4;
    }

    @include bp(l) {
      --columns: 6;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color('color-02');
    padding: toRem(20px) toRem(12px);
    border-radius: toRem(20px);
    aspect-ratio: 1.7;

    &--marked {
      border: 2px solid color('color-04');
    }

    @include bp(l) {
      aspect-ratio: 2.1;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: toRem(160px);
    }
  }
}
</style>
