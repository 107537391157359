<template>
  <div class="top-section">
    <div class="top-section__container bg--color-01">
      <div
        class="top-section__top-content container-new only-left"
        :class="{
          'container-new--only-left': false && !isMobile,
        }"
      >
        <div class="top-section__info">
          <h2
            class="top-section__title txt--bold txt--36px txt--height-44px txt--color-04 mb-20 mb-24--l"
          >
            {{ $t('welcome.topSection.title[0]') }}
            <arrows class="top-section__arrows" />
            {{ $t('welcome.topSection.title[1]') }}
          </h2>
          <p
            class="txt--weight-400 txt--16px txt--22px--l txt--height-20px txt--height-30px--l txt--color-03 mb-32 mb-48--l"
            v-html="$t('welcome.topSection.desc')"
          />
          <div class="top-section__btns">
            <!-- <exc-button
              class="top-section__btn top-section__start-btn"
              color="main"
              size="l"
              full-width
              :to="localePath('/exchange')"
            >
              {{ $t('welcome.topSection.startTrading') }}
              <div class="top-section__start-arrow">
                <start-arrow-icon />
              </div>
            </exc-button> -->
            <button-with-arrow
              full-width
              size="l"
              class="top-section__btn top-section__start-btn txt--center"
              :to="localePath('/exchange')"
            >
              {{ $t('welcome.topSection.startTrading') }}
            </button-with-arrow>
            <exc-button
              v-if="!loggedIn"
              class="top-section__btn top-section__login-btn"
              color="main"
              size="l"
              outline
              full-width
              :to="localePath('/login')"
            >
              {{ $t('welcome.topSection.logIn') }}
            </exc-button>
          </div>
        </div>

        <div class="top-section__slider-block">
          <slider-cards-container ref="hooper" show-slider pagination />
        </div>
      </div>

      <div class="top-section__stats container-new">
        <div
          v-for="(item, index) in stats"
          :key="stats[item]"
          class="top-section__stat-item"
        >
          <span
            class="top-section__stat-item-value txt--bold txt--26px txt--height-34px txt--color-04"
            :class="{
              'top-section__stat-item-value--with-plus': item.valueWithPlus,
            }"
          >
            <number-animation
              :ref="'number-' + index"
              :from="0"
              :to="item.to"
              :duration="1.5"
              :format="item.formatter || undefined"
            />
            <span v-if="item.labelAfterValue">
              {{ item.labelAfterValue }}
            </span>
          </span>
          <span
            class="top-section__stat-item-label txt--weight-400 txt--16px txt--22px--l txt--height-20px txt--height-30px--l txt--color-04"
          >
            {{ item.label }}
          </span>
        </div>
      </div>

      <div class="top-section__numbers container-new mt-20 mt-64--m">
        <div
          v-for="(service, index) in services"
          :key="services[service]"
          :to="service.to"
          :href="service.href"
          :target="service.withoutNewTab ? '_self' : '_blank'"
          class="txt--pointer"
          :class="`top-section__number-item-${index}`"
          @mouseover="onHoverLink(true, index)"
          @mouseleave="onHoverLink(false, index)"
        >
          <exc-link
            v-if="service.href"
            :href="service.href"
            target="_blank"
            class="top-section__number-item txt--pointer"
            :class="service.hovered ? service.bgOnHover : 'bg--color-01'"
          >
            <img :src="service.image" :alt="service.service">

            <div class="top-section__number-item-data">
              <div
                class="top-section__number-item-rate txt txt--26px txt--34px--l txt--height-34px txt--height-48px--l txt--color-04 txt--bold"
              >
                <number-animation
                  :ref="'service-' + index"
                  :from="0"
                  :to="service.rank || 0"
                  :duration="1.5"
                  :format="
                    v => `${service.withLattice ? '# ' : ''}${v.toFixed(0)}`
                  "
                />
              </div>
              <span
                class="txt txt--16px txt--20px--l txt--height-20px txt--height-30px--l txt--color-04"
              >
                {{ service.service }}
              </span>
            </div>
          </exc-link>
          <exc-link
            v-else
            :href="service.to"
            class="top-section__number-item txt--pointer"
            :class="service.hovered ? service.bgOnHover : 'bg--color-01'"
          >
            <img :src="service.image" :alt="service.service">

            <div class="top-section__number-item-data">
              <div
                class="top-section__number-item-rate txt txt--26px txt--34px--l txt--height-34px txt--height-48px--l txt--color-04 txt--bold"
              >
                <number-animation
                  :ref="'service-' + index"
                  :from="0"
                  :to="service.rank || 0"
                  :duration="1.5"
                  :format="
                    v => `${service.withLattice ? '# ' : ''}${v.toFixed(0)}`
                  "
                />
              </div>
              <span
                class="txt txt--16px txt--20px--l txt--height-20px txt--height-30px--l txt--color-04"
              >
                {{ service.service }}
              </span>
            </div>
          </exc-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcButton, ExcLink } from '@/components/common'
import { mapState } from 'vuex'
import NumberAnimation from 'vue-number-animation/Number.vue'
import SliderCardsContainer from '@/components/welcome/TopSection/SliderCardsContainer.vue'

const bigNumberFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
})

const numberFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  maximumFractionDigits: 2,
})

// const formatSmallIntNumber = Intl.NumberFormat('en', {
//   notation: 'compact',
//   maximumFractionDigits: 0,
// })

export default {
  name: 'TopSection',
  components: {
    NumberAnimation,
    ExcLink,
    ExcButton,
    SliderCardsContainer,
    ButtonWithArrow: () =>
      import('@/components/welcome/elements/ButtonWithArrow.vue'),
    Arrows: () => import('@/assets/img/welcome/top-section/arrows.svg?inline'),
    // StartArrowIcon: () => import('@/assets/img/welcome/start-arrow.svg?inline'),
  },
  data () {
    return {
      test0: false,
      test1: false,
      test2: false,
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.loggedIn,
      currencyCount: state => state.globals.currencyCount || 0,
      paymentsCount: state => state.globals.paymentsCount || 0,
      cmcDayVolume: state => state.globals.cmcDayVolume || 0,
      cmcMonthVolume: state => state.globals.cmcMonthVolume || 0,
      cmcExchangeRank: state => state.globals.cmcExchangeRank || 0,
      cgExchangeRank: state => state.globals.cgExchangeRank || 0,
      yearsOfHeritage: state => state.globals.yearsOfHeritage || 0,
      users: state => state.globals.users || 0,
      monthlyVisits: state => state.globals.monthlyVisits || 0,
    }),
    isMobile () {
      if (!this.$mq) return false
      return this.$mq?.resize && this.$mq?.below(991)
    },
    stats () {
      return [
        {
          to: this.yearsOfHeritage,
          label: this.$t('welcome.topSection.stats[0]'),
          labelAfterValue: this.$t('welcome.topSection.years'),
        },
        {
          to: this.cmcMonthVolume,
          formatter: this.formatVal30Days,
          label: this.$t('welcome.topSection.stats[1]'),
          valueWithPlus: true,
        },
        {
          to: this.users,
          formatter: this.formatIntNumber,
          label: this.$t('welcome.topSection.stats[2]'),
          valueWithPlus: false,
        },
        // {
        //   to: this.monthlyVisits,
        //   formatter: this.formatIntNumber,
        //   label: this.$t('welcome.topSection.stats[3]'),
        //   valueWithPlus: true,
        // },
      ]
    },
    services () {
      return [
        {
          rank: 2014,
          withLattice: false,
          to: this.localePath('/about-us'),
          service: this.$t('welcome.topSection.wasFounded'),
          bgOnHover: 'bg--color-10',
          withoutNewTab: true,
          hovered: this.test0,
          image: require(`@/assets/icons/welcome/was-founded${
            this.test0 ? '-hovered' : ''
          }.svg`),
        },
        {
          rank: this.cmcExchangeRank,
          href: 'https://coinmarketcap.com/exchanges/c-patex/',
          withLattice: true,
          service: `${this.$t('welcome.topSection.onService')} CoinMarketCap`,
          bgOnHover: 'bg--color-11',
          hovered: this.test1,
          withoutNewTab: false,
          image: require(`@/assets/icons/welcome/cmc${
            this.test1 ? '-hovered' : ''
          }.svg`),
        },
        {
          rank: this.cgExchangeRank,
          href: 'https://www.coingecko.com/en/exchanges/c_patex',
          withLattice: true,
          service: `${this.$t('welcome.topSection.onService')} CoinGecko`,
          bgOnHover: 'bg--color-09',
          hovered: this.test2,
          withoutNewTab: false,
          image: require(`@/assets/icons/welcome/coingecko${
            this.test2 ? '-hovered' : ''
          }.svg`),
        },
      ]
    },
  },
  created () {
    if (process.client) {
      console.log(document.documentElement.clientWidth < 991)
    }
  },
  methods: {
    onHoverLink (hoverParam, index) {
      this[`test${index}`] = hoverParam
    },
    formatIntNumber (value) {
      return numberFormatter.format(value)
    },
    // formatSmallIntNumber (value) {
    //   return formatSmallIntNumber.format(value)
    // },
    formatCoins (value) {
      return `${value.toFixed(0)}`
    },
    formatVal24H (value) {
      return bigNumberFormatter.format(value)
    },
    formatVal30Days (value) {
      return bigNumberFormatter.format(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.top-section {
  overflow-x: hidden;

  &__container {
    padding-top: toRem(20px);
    padding-bottom: toRem(32px);

    @include bp(l) {
      padding-top: toRem(96px);
      padding-bottom: toRem(96px);
    }
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    gap: toRem(32px);
    margin-bottom: toRem(16px);

    @include bp(l) {
      display: grid;
      grid-template-columns: 50% 50vw;
      margin-bottom: toRem(60px);
      margin-right: auto;

      .only-left {
        // only left
        @include with-bp(padding-right, 0);
        @include with-bp(margin-right, 0);
        @include with-bp(max-width, $containerMaxWidthNew);
      }
    }
  }

  &__info {
    height: max-content;
  }

  &__login-btn ::v-deep span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include bp(l) {
      font-size: toRem(60px);
      line-height: toRem(72px);
    }
  }

  &__arrows {
    width: 100%;
    max-width: toRem(44px);

    @include bp(l) {
      max-width: toRem(77px);
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    gap: toRem(16px);

    @include bp(m) {
      flex-direction: row;
    }
  }

  &__btn {
    @include bp(l) {
      max-width: max-content;
    }
  }

  &__slider-block {
    @include bp(0, m) {
      margin-right: toRem(-18px);
    }

    @include bp(m, l) {
      margin-right: toRem(-32px);
    }
    overflow: hidden;
  }

  &__stats {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(1, 1fr);
    gap: toRem(40px);

    @include bp(m) {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
      gap: toRem(16px);
      grid-template-rows: unset;
    }
  }

  &__stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: toRem(10px);
    border-left: 1px solid color(transp-03);

    @include bp(m) {
      border: none;
      padding: 0;
      gap: toRem(14px);
    }

    // @include bp($w-media-desktop) {
    //   flex-direction: row;
    // }
  }

  &__stat-item-value {
    @include bp(l) {
      font-size: toRem(44px);
      line-height: toRen(58px);
    }

    &--with-plus {
      span {
        position: relative;

        &:first-child::after {
          content: '+';
          position: absolute;
          right: toRem(-15px);

          @include bp(l) {
            right: toRem(-24px);
          }
        }
      }
    }
  }

  &__numbers {
    display: flex;
    flex-direction: column;
    grid-template-areas: 'first' 'second' 'third';
    gap: toRem(16px);

    @include bp(m) {
      display: grid;
      grid-template-areas: 'first first' 'second third';
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @include bp(ml) {
      gap: toRem(24px);
      grid-template-areas: 'first second third';
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
  }

  &__number-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: toRem(16px);
    padding: toRem(16px);
    border-radius: toRem(20px);
    box-shadow: 0px 1px 20px 0px #1618220f;
    // min-height: toRem(188px);
    transition-duration: 0.3s;

    img {
      width: toRem(66px);
      height: toRem(66px);
      transition-duration: 0.3s;
    }

    @include bp(m) {
      display: grid;
      grid-template-columns: toRem(100px) 1fr;
    }

    @include bp(l) {
      img {
        width: toRem(100px);
        height: toRem(100px);
      }
    }

    @include bp(ml) {
      gap: toRem(24px);
      padding: toRem(24px) toRem(20px);
    }
  }

  &__number-item-0 {
    grid-area: first;
  }

  &__number-item-1 {
    grid-area: second;
  }

  &__number-item-2 {
    grid-area: third;
  }

  &__number-item-rate {
    background-color: color('transp-03');
    border-radius: toRem(200px);
    padding: toRem(4px) toRem(24px);
    max-width: fit-content;
  }

  &__number-item-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: toRem(12px);

    @include bp(m) {
      justify-content: center;
      align-items: unset;
    }
  }
}
</style>
