<template>
  <div class="welcome-faq-block">
    <welcome-faq-item v-for="post in posts" :key="post.id" :post="post" />
  </div>
</template>

<script>
export default {
  name: 'WelcomeFaqBlock',
  components: {
    WelcomeFaqItem: () =>
      import(
        /* webpackMode: "eager" */ '@/components/welcome/FaqComponents/WelcomeFaqItem.vue'
      ),
  },
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
