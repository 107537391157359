<template>
  <div
    id="slider-cards-container"
    :class="classes"
    class="slider-cards-container txt--color-09"
  >
    <!-- :center-mode="true" -->
    <!-- :items-to-show="isMobile ? 1.025 : 1.25" -->
    <!-- {{ settingsSetter }} -->
    <!-- {{ currentSlide }} -->
    <hooper
      v-slot="{ isSliding }"
      :key="settingsSetter"
      auto-play
      :settings="settings"
      :wheel-control="false"
      :infinite-scroll="true"
      style="height: auto;"
      class="slider-cards-container__hooper"
      @slide="setCurrentSlide"
    >
      <slide
        v-for="(item, index) in sliderItems"
        :key="`slide--${index}-${currentSlide}`"
        :duration="5000"
        :index="index + 1"
        class="slider-cards-container__hooper--slide main-slide"
      >
        <!-- {{ currentSlide }} {{ index }} {{ item.isActive }} -->
        <introducing-slider-item
          v-bind="{ slide: item, isSliding, isActive: index === currentSlide }"
          style="margin-right: 24px;"
        />
      </slide>
    </hooper>

    <div v-if="pagination" class="slider-cards-container__pagination">
      <div
        v-for="(slide, index) in sliderItems"
        :key="index"
        class="slider-cards-container__pagination-item"
        :class="{
          'slider-cards-container__pagination-item--active':
            index === currentSlide,
        }"
      />
    </div>
  </div>
</template>

<script>
import IntroducingSliderItem from '@/components/welcome/TopSection/IntroducingSliderItem.vue'
import { Hooper, Slide } from 'hooper-cnb'
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'SliderCardsContainer',
  components: {
    IntroducingSliderItem,
    Hooper,
    Slide,
  },
  props: {
    pagination: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      currentSlide: 0,
      settingsSetter: 1,
      settings: {},

      prevIsMobile: false,
    }
  },

  computed: {
    ...mapState({}),
    ...mapGetters({}),
    isDesktop () {
      return this.$mq?.resize && !this.$mq?.below(991)
    },
    isMobile () {
      return this.$mq?.resize && this.$mq?.below(768)
    },
    classes () {
      return {
        'slider-cards-container--with-pagination': this.pagination,
      }
    },
    sliderItems () {
      return [
        {
          title: this.$t('welcome.topSection.slider[2].title'),
          text: this.$t('welcome.topSection.slider[2].text'),
          link: '/network',
          img: require('@/assets/img/welcome/top-section/slider-network.png'),
          gif: require('@/assets/img/welcome/top-section/slider-network.gif'),
          color: 'color-11',
        },
        {
          title: this.$t('welcome.topSection.slider[0].title'),
          text: this.$t('welcome.topSection.slider[0].text'),
          link: '/trade',
          img: require('@/assets/img/welcome/top-section/slider-fast-trading.png'),
          gif: require('@/assets/img/welcome/top-section/slider-fast-trading.gif'),
          color: 'color-09',
        },
        // {
        //   title: this.$t('welcome.topSection.slider[1].title'),
        //   text: this.$t('welcome.topSection.slider[1].text'),
        //   link: '/network',
        //   img: require('@/assets/img/welcome/top-section/slider-cpex.png'),
        //   gif: require('@/assets/img/welcome/top-section/slider-cpex.gif'),
        //   color: 'color-10',
        // },
        {
          title: this.$t('welcome.topSection.slider[3].title'),
          text: this.$t('welcome.topSection.slider[3].text'),
          link: '/crypto-campus',
          img: require('@/assets/img/welcome/top-section/slider-campus.png'),
          gif: require('@/assets/img/welcome/top-section/slider-campus.gif'),
          color: 'color-10',
        },
      ]
    },
  },
  created () {
    this.setSettings(false)

    if (process.client) {
      this.$watch('$mq.resize', debounce(this.setSettings, 300))
      // debugger
    }
  },
  mounted () {
    window.dispatchEvent(new Event('resize'))
    this.settingsSetter++
  },
  methods: {
    setCurrentSlide (payload) {
      const firstIndex = 0
      const lastIndex = this.sliderItems.length - 1

      const selectedIndex = payload.currentSlide

      if (selectedIndex > lastIndex) this.currentSlide = firstIndex
      else if (selectedIndex < firstIndex) this.currentSlide = lastIndex
      else this.currentSlide = selectedIndex
    },
    setSettings (reRenderSlider = true) {
      // only from tablet/pc to mobile and from only from mobile to another device (resize)
      if (this.isMobile) {
        if (this.prevIsMobile) return
        this.prevIsMobile = true
      } else {
        this.prevIsMobile = false
      }

      // console.log('set slider bp settings...', reRenderSlider)

      this.settings = {
        breakpoints: generateBreakpoints(this.isMobile, this.$mq?.below(991)),
      }
      if (reRenderSlider) this.settingsSetter++
    },
  },
}

function generateBreakpoints (isMobile, isDesktop) {
  const cardWidth = isMobile ? 260 : 504
  const bps = {}

  let bp = 2850 / 2
  const spacing = isMobile ? 24 : 20

  while (bp > 10) {
    const value = (bp / cardWidth) * (isDesktop && !isMobile ? 1.85 : 1)
    bps[bp * 2] = { itemsToShow: value < 1.04 ? 1.04 : value }
    // bps[bp] = { itemsToShow: value < 1.04 ? 1.04 : value }
    bp = bp - spacing
  }

  return bps
}
</script>

<style lang="scss" scoped>
.slider-cards-container {
  $root: &;

  display: flex;
  margin-top: toRem(-10px);
  //  overflow: auto;

  &__hooper {
    ::v-deep {
      .main-slide {
        height: auto !important;
      }
    }
  }

  &--with-pagination {
    flex-direction: column;
  }

  &__pagination {
    display: flex;
    gap: toRem(7px);
    align-items: center;
    justify-content: center;
    height: toRem(10px);

    @include bp(l) {
      justify-content: unset;
      margin-left: calc(504px / 2 - 36px) !important;
    }
  }

  &__pagination-item {
    width: toRem(6px);
    height: toRem(6px);
    background: #d1d1d1;
    border-radius: 50%;
    transition: 0.2s;

    &--active {
      width: toRem(10px);
      height: toRem(10px);
      background: color('color-05');
    }
  }

  & > * {
    margin: 15px 0;
  }
}

.hooper-slide {
}

@include bp(l) {
  ::v-deep {
    .hooper-list {
      overflow: visible !important;
    }

    //    .hooper-slide {
    //      width: toRem(510px) !important;
    //    }
  }
}
</style>
