<template>
  <div class="our-products bg--color-10">
    <div class="container-wrapper bg--color-01">
      <div class="our-products__container container-new">
        <div class="our-products__top-block">
          <!-- <div class="our-products__info">
          </div> -->
          <span
            class="txt--bold txt--36px txt--52px--l txt--height-44px txt--height-64px--l txt--color-04 mb-0"
          >
            {{ $t('welcome.ourProducts.title') }}
          </span>
          <p
            class="txt--weight-400 txt--16px txt--22px--l txt--height-20px txt--height-30px--l txt--color-03 mb-0"
          >
            {{ $t('welcome.ourProducts.desc') }}
          </p>
          <!-- <exc-button
            color="dark"
            outline
            :size="btnSize"
            :to="localePath('/exchange')"
            class="mt-10"
          >
            {{ $t('welcome.ourProducts.startTrading') }}
          </exc-button> -->
        </div>

        <div class="our-products__links mt-32 mt-48--m">
          <exc-link
            v-for="(link, index) in links"
            :key="index"
            :class="{
              'our-products__link--wide': link.wide,
              disabled: link.disabled,
            }"
            class="our-products__link"
            :to="link.disabled ? '' : link.to"
          >
            <div
              class="our-products__link-content"
              @mouseover="link.disabled ? null : onHoverLink(true, index)"
              @mouseleave="link.disabled ? null : onHoverLink(false, index)"
            >
              <div class="our-products__link-info">
                <span
                  class="txt--bold txt--22px txt--26px--l txt--height-30px txt--height-34px--l txt--color-04 mb-6"
                >
                  {{ link.title }}
                </span>
                <p
                  class="txt--weight-400 txt--16px txt--22px--l txt--height-20px txt--height-30px--l txt--color-04 mb-28"
                >
                  {{ link.desc }}
                </p>
                <span v-if="link.disabled" class="our-products__view-more-link">
                  {{ $t('common.promo.comingSoon') }}
                </span>
                <exc-link v-else class="our-products__view-more-link">
                  {{ $t('welcome.ourProducts.viewMore') }}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.6113 10.5469C15.6113 10.3733 15.5441 10.2214 15.4095 10.0912L11.347 6.02867C11.2732 5.95922 11.1973 5.90931 11.1191 5.87893C11.0454 5.8442 10.9694 5.82684 10.8913 5.82684C10.7177 5.82684 10.5723 5.88327 10.4551 5.99611C10.3422 6.10896 10.2858 6.25002 10.2858 6.41929C10.2858 6.50176 10.301 6.58205 10.3314 6.66018C10.3618 6.7383 10.403 6.80341 10.4551 6.85549L11.8353 8.25523L14.2051 10.4167L14.3809 10.0586L12.3171 9.93492H4.68685C4.50456 9.93492 4.35482 9.99351 4.23763 10.1107C4.12478 10.2235 4.06836 10.3689 4.06836 10.5469C4.06836 10.7292 4.12478 10.8789 4.23763 10.9961C4.35482 11.109 4.50456 11.1654 4.68685 11.1654H12.3171L14.3809 11.0352L14.2051 10.6901L11.8353 12.8386L10.4551 14.2448C10.403 14.2969 10.3618 14.362 10.3314 14.4401C10.301 14.5139 10.2858 14.5942 10.2858 14.681C10.2858 14.8503 10.3422 14.9913 10.4551 15.1042C10.5723 15.217 10.7177 15.2735 10.8913 15.2735C11.0519 15.2735 11.1973 15.2084 11.3275 15.0781L15.4095 11.0091C15.5441 10.8789 15.6113 10.7248 15.6113 10.5469Z"
                      fill="currentColor"
                    />
                  </svg>
                </exc-link>
              </div>
              <div
                class="our-products__link-img"
                :class="`our-products__${link.class}`"
              >
                <img
                  v-if="!isMobile && loaded"
                  v-show="isMobile ? false : link.showGiff"
                  :src="link.giff"
                  :alt="`${link.title} gif`"
                  loading="lazy"
                  :width="link.width"
                  :height="link.height"
                >
                <img
                  v-show="!loaded ? true : isMobile ? true : !link.showGiff"
                  :src="link.img"
                  :alt="`${link.title} image`"
                  loading="lazy"
                  :width="link.width"
                  :height="link.height"
                >
              </div>
            </div>
          </exc-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExcButton, ExcLink } from '@/components/common'
import { mapState } from 'vuex'

export default {
  name: 'OurProducts',
  components: {
    ExcButton,
    ExcLink,
  },
  data () {
    return {
      loaded: false,

      test0: false,
      test1: false,
      test2: false,
      test3: false,
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.loggedIn,
    }),
    isMobile () {
      if (!process.client) return true
      return this.$mq?.resize && this.$mq?.below(991)
    },
    btnSize () {
      return this.isMobile ? 'm' : 'l'
    },
    links () {
      return [
        {
          title: this.$t('welcome.ourProducts.links[0].title'),
          desc: this.$t('welcome.ourProducts.links[0].desc'),
          wide: true,
          showGiff: this.test0,
          class: 'launchpad',
          to: this.localePath('/launchpad'),
          giff: require('@/assets/videos/img0.gif'),
          img: require('@/assets/videos/img0.png'),
          width: '329px',
          height: '165px',
          disabled: true,
        },
        {
          title: this.$t('welcome.ourProducts.links[1].title'),
          desc: this.$t('welcome.ourProducts.links[1].desc'),
          showGiff: this.test1,
          class: 'earn-system',
          to: this.localePath('/c-freezer'),
          giff: require('@/assets/videos/img1.gif'),
          img: require('@/assets/videos/img1.png'),
          width: '202px',
          height: '170px',
        },
        {
          title: this.$t('welcome.ourProducts.links[2].title'),
          desc: this.$t('welcome.ourProducts.links[2].desc'),
          showGiff: this.test2,
          class: 'referrals',
          to: this.localePath(this.loggedIn ? '/user/referrals' : '/login'),
          giff: require('@/assets/videos/img2.gif'),
          img: require('@/assets/videos/img2.png'),
          width: '207px',
          height: '175px',
        },
        {
          title: this.$t('welcome.ourProducts.links[3].title'),
          desc: this.$t('welcome.ourProducts.links[3].desc'),
          wide: true,
          showGiff: this.test3,
          class: 'spot-trading',
          to: this.localePath('/exchange'),
          giff: require('@/assets/videos/img3.gif'),
          img: require('@/assets/videos/img3.png'),
          width: '250px',
          height: '211px',
        },
      ]
    },
  },
  mounted () {
    this.loaded = true
  },
  methods: {
    onHoverLink (hoverParam, index) {
      this[`test${index}`] = hoverParam
    },
  },
}
</script>

<style lang="scss" scoped>
.our-products {
  $root: &;
  // padding: toRem(20px) 0;
  // background: color('color-01');
  // border-radius: toRem(20px);

  // @include bp(l) {
  //   padding: toRem(60px) 0;
  //   border-radius: toRem(60px);
  // }

  &__top-block {
    // display: flex;
    // flex-direction: column;
    // gap: toRem(24px);

    // @include bp(l) {
    //   flex-direction: row;
    //   justify-content: space-between;
    //   align-items: center;
    // }

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 850px;
    gap: toRem(18px);
    margin: 0 auto;

    p,
    span {
      text-align: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: toRem(24px);

    @include bp(l) {
      gap: toRem(18px);
      width: 50%;
    }
  }

  &__links {
    display: grid;
    grid-template-columns: 1fr;
    gap: toRem(12px);

    @include bp(m) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__link {
    border-radius: toRem(20px);
    background: color('color-02');
    min-height: toRem(214px);
    transition: 0.3s;
    padding: toRem(24px) toRem(30px);

    &.disabled {
      cursor: default !important;
      opacity: 0.7;

      #{$root}__view-more-link {
        cursor: default !important;
      }
    }

    &:not(.disabled):hover {
      background: color('color-11');
    }

    @include bp(m) {
      padding: 0;
      grid-column: span 5;

      &--wide {
        grid-column: span 7;
      }
    }
  }

  &__link-content {
    display: flex;
  }

  &__link-img {
    margin: auto 0 0 0;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    @include bp(0, m) {
      margin-left: auto;
    }
  }

  &__launchpad {
    border-top: toRem(5px);
    overflow: hidden;

    img {
      margin-top: toRem(-5px);
      max-width: toRem(244px);
      max-height: toRem(144px);
    }

    @include bp(l) {
      img {
        max-width: toRem(410px);
        max-height: none;
        height: 100%;
      }
    }
  }

  &__earn-system {
    img {
      max-width: toRem(164px);
      max-height: toRem(174px);
    }

    @include bp(l) {
      img {
        max-width: toRem(202px);
        max-height: none;
        height: 100%;
      }
    }
  }

  &__referrals {
    img {
      max-width: toRem(168px);
      max-height: toRem(173px);
    }

    @include bp(l) {
      img {
        max-width: toRem(207px);
        max-height: none;
        height: 100%;
      }
    }
  }

  &__spot-trading {
    img {
      max-width: toRem(211px);
      max-height: toRem(180px);
    }

    @include bp(l) {
      img {
        max-width: toRem(250px);
        max-height: none;
        height: 100%;
      }
    }
  }

  &__view-more-link {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    gap: toRem(5px);
    align-items: center;
    font-weight: 700;
    margin-top: auto;

    svg {
      transition-duration: 0.25s;
    }

    &:hover {
      svg {
        transform: translateX(3px);
      }
    }
  }

  &__link-info {
    display: flex;
    flex-direction: column;
    max-width: 55%;

    @include bp(m) {
      padding: toRem(24px) 0 toRem(24px) toRem(30px);
    }
  }
}
</style>
