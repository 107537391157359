<template>
  <section
    v-if="activities.length"
    class="activities-section"
  >
    <div class="container-wrapper bg--color-02">
      <div class="activities-section__container container-new">
        <div class="activities-section__head">
          <div class="activities-section__title">
            {{ $t('welcome.activitiesSection.title') }}
          </div>
        </div>

        <div class="activities-section__carousel-wrapper">
          <client-only>
            <hooper
              ref="carousel"
              :key="carouselKey"
              :settings="settings"
              :auto-play="isEnabled"
              style="height: auto;"
              class="activities-section__carousel"
              @slide="onSlide"
            >
              <slide
                v-for="(item, index) in activities"
                :key="index"
                :duration="5000"
              >
                <div class="activities-section__carousel-slide">
                  <component
                    :is="item.link ? 'a' : 'div'"
                    class="activities-section__item sha--03"
                    v-bind="{
                      ...(item.link ? {
                        href: item.link,
                        target: '_blank',
                        rel: 'noopener'
                      } : {})
                    }"
                  >
                    <img
                      :src="item.image"
                      loading="lazy"
                    >
                  </component>
                </div>
              </slide>
            </hooper>
          </client-only>
        </div>

        <div
          v-if="isEnabled"
          class="activities-section__controls"
        >
          <div
            class="activities-section__control"
            @click="slidePrev"
          >
            <start-arrow-icon style="rotate: 180deg;" />
          </div>

          <div class="activities-section__pagination">
            <div
              v-for="(slide, index) in activities"
              :key="index"
              :class="['activities-section__pagination-item', {
                'activities-section__pagination-item--active': index === currentSlide
              }]"
            />
          </div>

          <div
            class="activities-section__control"
            @click="slideNext"
          >
            <start-arrow-icon />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper-cnb'

export default {
  name: 'ActivitiesSection',

  components: {
    Hooper,
    Slide,
    StartArrowIcon: () => import('@/assets/img/welcome/start-arrow.svg?inline'),
  },

  props: {
    activities: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      carouselKey: 0,
      currentSlide: 0,
    }
  },

  computed: {
    itemsToShow () {
      return [
        [0, 1],
        [768, 2],
        [992, 3],
        [1280, 4],
      ].map(([breakpoint, itemsToShow]) => ({
        isActive: this.$mq?.resize && this.$mq?.above(breakpoint),
        itemsToShow,
      })).findLast(({ isActive }) => isActive).itemsToShow
    },
    isEnabled () {
      return this.activities.length > this.itemsToShow
    },
    settings () {
      return {
        wheelControl: false,
        itemsToShow: this.itemsToShow,
        mouseDrag: false,
        touchDrag: false,
        infiniteScroll: this.isEnabled,
        autoPlay: this.isEnabled,
      }
    },
  },

  watch: {
    settings: {
      handler () {
        this.carouselKey++
      },
      deep: true,
    },
  },

  methods: {
    slideNext () {
      this.$refs.carousel?.slideNext()
    },
    slidePrev () {
      this.$refs.carousel?.slidePrev()
    },
    onSlide ({ currentSlide }) {
      const firstIndex = 0
      const lastIndex = this.activities.length - 1

      if (currentSlide > lastIndex) this.currentSlide = firstIndex
      else if (currentSlide < firstIndex) this.currentSlide = lastIndex
      else this.currentSlide = currentSlide
    },
  },
}
</script>

<style lang="scss" scoped>
.activities-section {
  &__container {
    @include bp(l) {
      display: grid;
      grid-template-areas:
        'head controls'
        'carousel carousel';
      grid-template-columns: minmax(0, 1fr) auto;
      gap: toRem(40px) toRem(24px);
    }
  }

  &__head {
    margin-bottom: toRem(32px);

    @include bp(l) {
      grid-area: head;
      margin: 0;
    }
  }

  &__title {
    font-size: toRem(36px);
    line-height: toRem(44px);
    font-weight: 700;
    color: color('color-04');
    text-align: center;

    @include bp(l) {
      font-size: toRem(44px);
      line-height: toRem(58px);
      text-align: left;
    }
  }

  &__carousel-wrapper {
    padding: 0 toRem(20px);

    @include bp(m) {
      padding: 0;
    }

    @include bp(l) {
      grid-area: carousel;
    }
  }

  &__carousel {
    width: calc(100% + #{toRem(24px)}) !important;
    margin: toRem(-12px);
  }

  &__carousel-slide {
    padding: toRem(12px);
  }

  &__item {
    display: block;
    border-radius: toRem(20px);
    background-color: color('color-01');
    aspect-ratio: 1.5;
    overflow: hidden;
    position: relative;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      pointer-events: none;
      user-select: none;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(8px);
    margin-top: toRem(32px);

    @include bp(l) {
      grid-area: controls;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    height: toRem(24px);
    border-radius: toRem(12px);
    border: 1px solid color('color-05');
    padding: 0 toRem(7px);
    gap: toRem(8px);
  }

  &__pagination-item {
    width: toRem(8px);
    height: toRem(8px);
    border-radius: 50%;
    background-color: color('transp-01');

    &--active {
      background-color: color('color-05');
    }
  }

  &__control {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: toRem(24px);
    border-radius: toRem(12px);
    border: 1px solid color('color-05');
    padding: 0 toRem(7px);

    svg {
      width: toRem(32px);
      height: auto;
    }
  }
}
</style>
